@import "../variables";

html{
  height:100%;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip, .map__popup {
  min-width: 340px
}

.map__popup h3, .map__popup img{
  text-align: center
}
.map__popup p {
  margin: 5px
}
/*.panneaux--client{
  margin-left: 10px !important;
  width: 100% !important;
}*/
.panneaux--client td, .panneaux--client th{
  padding: 5px 8px
}
/*.panneaux--client table{
  margin : 0px auto
}*/
.container-fichepose{
  width: 90%;
  max-width: 800px;
  margin: 0px auto;
  padding: 20px;
  background-color: $lighter-color
}
.container-fichepose img{
  width: 100%
}
tr.hoverified:hover td{
  background-color: $secondary-color;
  cursor: pointer;
  color: $white-color
}

tbody th.th--client {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0px;
  background: #FFF;
  border-right: 1px solid #CCC;
  text-align: center;
}

.logoPA{
  max-height: 40px
}

.container-fichepose h3{
  text-transform: uppercase;
}
#waitIcon{
  -webkit-animation:spin 4s linear infinite;
   -moz-animation:spin 4s linear infinite;
   animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
