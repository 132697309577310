@import "../variables";


// ----------------------------------
// ---------- TABLEAU ---------------
// ----------------------------------

$cellWidth: 200px;

.panneaux {
  max-width: 100%;
  max-height: 100%;
  overflow: scroll;
  position: relative;
}

table {
  position: relative;
  border-collapse: collapse;
}

td, th {
  padding: 3px;
}

thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: #FFF;
}

thead th:first-child {
  left: 0;
  z-index: 1;
}

thead th:nth-child(2), thead th:nth-child(3){
  z-index: 1;
}

tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 34px;
  background: #FFF;
  border-right: 1px solid #CCC;
  text-align: center;
}

th.headcol{
  text-align: left;
  //min-width : $cellWidth;
  cursor: pointer;
}

th.headcol__index{
  min-width : 40px;
  left : 0px
}
th.headcol__client{
  min-width : 200px;
  left : 40px
}
th.headcol__reference{
  min-width : 100px;
  left : 240px
}

th.headcol-index {
  font-weight: lighter;
  color: $danger-color;
  text-align: right;
  //min-width : 0px;
  cursor: pointer;
  left: 0px;
}

th button {
  min-width: 0px
}
.panneaux::-webkit-scrollbar {
    -webkit-appearance: none;
}

.panneaux::-webkit-scrollbar:vertical {
    width: 11px;
}

.panneaux::-webkit-scrollbar:horizontal {
    height: 11px;
}

.panneaux::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

td {
  text-align: center;
  background-color: $light-color;
  border: solid 1px #FFF;
  padding: 3px;
  word-wrap:break-word;
  transition-property: background-color;
  transition-duration: 200ms;
  font-size: 14px
}

td.minWidth , th.minWidth {
  min-width: $cellWidth;
  text-align: left
}

td.med-left {
  text-align: left;
  min-width : $cellWidth;
}
td.med-center {
  text-align: center;
  min-width : $cellWidth;
}
td.big-left {
  text-align: left;
  min-width : 500px;
}
td.small-right {
  text-align: right;
}
td.small-center {
  text-align: center;
  min-width : 100px
}
td.xsmall-center {
  text-align: center;
}
thead tr th{
  border-bottom: 1px solid #CCC;
  color: #CCC;
}

.square {
  height: 20px;
  width: 20px;
  margin: 0px auto;
  border: 2px solid #000;
  background-color: #FFF;
  border-radius: 2px
}
.square-true {
  background-color: #000;
}
.row-selected {
  background-color: #9cd0e4
}
th.row-selected{
  background-color: #9cd0e4
}
.row-red{
  background-color: #ff8d8d
}
.row-purple{
  background-color: #bb9ce4
}
th button{
  padding: 3px;
  font-size: 12px;
  width: 100%;
  height: 100%;
  font-weight: bold;
  text-align: center;
  color: #8e8d8d;

}

th button:hover{
  color: #000
}

table .button-download {
  background-color: #9cd0e4;
  padding: 2px;
}
table .button-download svg {
  max-height: 20px
}
.panneaux--client td.med-left {
  text-align: left;
  min-width : 0px;
}
.panneaux--client td.med-center {
  text-align: center;
  min-width : 0px;
}
.panneaux--client td.big-left {
  text-align: left;
  min-width : $cellWidth;
}
.panneaux--client td.small-right {
  text-align: right;
}
.panneaux--client td.small-center {
  text-align: center;
  max-width : 1000px
}
