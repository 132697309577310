@import "../variables";

tr.hoverified:hover td{
  background-color: $secondary-color;
  cursor: pointer;
  color: $white-color
}

.table_connection {
  width: 100%
}

tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0px;
  background: #FFF;
  border-right: 1px solid #CCC;
  text-align: center;
}

.table_connection td, .table_connection th{
  padding: 10px
}

.container__connection {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.button__back {
  background-color: $light-color;
  color: #FFF;
  padding: 10px 20px;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 40px;
}

.button__refresh {
  background-color: $secondary-color;
  color: #FFF;
  padding: 10px 20px;
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
