.filter {
  position : fixed;
  top: 60px;
  background-color : #FFF;
  height : 100%;
  min-width: 300px;
  z-index: 10000;
  padding: 10px;
  box-shadow: 0px 6px 13px #000;
  overflow-y: scroll;
}

.filter__choice {
  cursor: pointer;
}
.checkbox-filter{
  width: auto !important;
  height: auto !important;
  left : 0px !important;
  top : -2px
}
