.form {
  width: 90%;
  margin: 0px auto
}
button.choose-image {
  border : 1px solid #000;
  padding: 20px;
  margin-bottom: 20px
}
.container-divisible{
  display: flex;
}
.form{
  max-width: 1000px;
  margin: 0px auto
}
.img-responsive{
  max-width: 100%
}
.onethird {
  width: 33%;
  padding-right: 10px
}
