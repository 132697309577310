@import "../variables";

.toolbar--choice{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.toggle-button {
  background: $primary-color;
  border-radius: 16.5px;
  width: 40px;
  height : 20px;
  margin: 10px
}

.toggle-button__circle{
  background-color: #FFF;
  height: 100%;
  width: 20px;
  border-radius: 20px;
  border: 2px solid $primary-color;
  position: relative;
}
