@import "variables";

* {
  box-sizing: border-box
}
body {
  font-family: $font-stack;
  color : $secondary-color;
  margin: 0px;

}
svg {
  margin: 0px 5px
}
button {
  border: 0px;
  padding: 0px 10px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 100px;
  justify-content: center;
}
.container {
  margin-top: 120px;
  width: 100%
}
.left, .right {
  width: 50%;
  padding-right: 10px
}
.flex{
  display: flex
}

.container--home {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.container--home button {
  background-color: $primary-color;
  color: #FFF;
  padding: 20px 40px;
  font-size: 20px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px
}
.container--home img{
  width : 80%;
  max-width: 400px
}
.button-primary{
  background-color: $primary-color;
  color: #FFF;
  padding: 10px 20px;
  font-size: 20px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px
}

.button-primary:hover{
  opacity: 0.5
}

.button-secondary{
  border: solid 1px $primary-color;
  color: #000;
  padding: 10px 20px;
  font-size: 20px;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px
}

.button-secondary:hover{
  background-color: $primary-color;
  color: #FFF;
}

.button-primary--small{
  padding: 5px 10px;
  font-size: 12px;
  margin-top: 0px
}
button.center svg {
  height : 40px;
  margin-left: auto;
  margin-right: auto
}
.no-margin{
  margin : 0px auto
}
#backbutton{
  height: 20px
}
button:hover #backbutton g{
  fill: #FFF
}
