@import "../variables";

.toolbar{
  padding: 10px;
  width: 100%;
  background-color: $lighter-color;
  position: fixed;
  top: 60px;
  z-index: 1;
  display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.toolbar__button-container {
  display: flex;

}

.toolbar__button-container button {
  margin-right: 10px
}
