@import "../variables";

// Form
form {
  width: 90%;
  margin: 0px auto
}
// Inputs
input[type=text],
.container-checkbox,
input[type=number],
input[type=date],
select,
textarea {
  width: 100%;
  font-size: 18px;
  padding: 30px 0px 17px 20px;
  border-radius: 2px;
  border: solid 1px $light-color;
  margin-bottom: -10px;
  font-family: $font-stack;
  outline-color: $primary-color;
  background-color: #FFF
}

input[type=text].red,
input[type=number].red,
input[type=date].red,
select.red,
textarea.red {
  border: solid 3px #ff0b0b;
}

input[type=text].grey,
input[type=number].grey,
input[type=date].grey,
select.grey,
textarea.grey {
  background-color: #f9f7f7
}

input[type=text] + label,
input[type=number] + label,
input[type=date] + label,
select + label,
textarea + label {
  position: relative;
  color: $light-color;
  font-size: 18px;
  margin-left: 21px;
  margin-top: 15px;
  font-family: $font-stack;
  top: -35px
}

textarea + label {
  top : -172px;
}

input[type=text] + label.small,
input[type=number] + label.small,
input[type=date] + label.small,
select + label.small{
  color: $secondary-color;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 15px;
  top : -50px
}
textarea + label.small{
  color: $secondary-color;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 15px;
  top : -201px
}

input[type=text]:focus + label ,
input[type=number]:focus +label ,
input[type=date]:focus +label ,
select:focus + label{
  color: $primary-color;
  font-weight: bold;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 15px;
  top : -50px
}

textarea:focus + label{
  color: $primary-color;
  font-weight: bold;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 15px;
  top : -201px
}

input[type=text]:disabled,
input[type=number]:disabled,
input[type=date]:disabled,
select:disabled,
textarea:disabled {
  border: solid 0px $light-color;
  color: $secondary-color;
}

input[type=text]:disabled + label,
input[type=number]:disabled + label,
input[type=date]:disabled + label,
select:disabled + label {
  color: $secondary-color;
  font-weight: bold;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 15px;
  top : -50px
}
textarea:disabled + label{
  color: $secondary-color;
  font-weight: bold;
  font-size: 12px;
  margin-left: 22px;
  margin-top: 15px;
  top : -201px
}

.container-input-select{
  display: flex
}
.container-input-and-label{
  width: 70%;
}
.container-input-and-label input[type=text]{
  border-radius: 2px 0px 0px 2px;
}

.container-input-select .choose-button{
  width: 30%;
  height: 71px;
  background-color: $light-color;
  font-size: 18px;
  border-radius: 0px 2px 2px 0px;
  border: solid 1px $light-color;
  font-family: $font-stack;
  outline-color: $primary-color;
}

.container-input-select .choose-button p{
  text-align: center;
  margin-top: 20px
}

.listOfOptions{
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}
.optionsRadio{
  padding: 10px;
  margin: 5px auto;
  width: 30%;
  background-color: #FFF;
  display: flex;
  align-items: center;
}

.optionsRadio label{
  margin-top: -2px;
  padding-left: 7px
}

.container-checkbox {
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 80% 20%;
}
.container-checkbox label{
  margin-bottom: 12px
}
.container-checkbox input{
 justify-self: center;
}
.container-checkbox--blue {
  background-color: #d2d7e5
}
.checkmark {
  width: 20px;
  height : 20px;
  border : solid 2px $secondary-color;
}
input[type=checkbox] {
  height: 20px;
  width: 33px;
  position: relative;
  left : 10px;
  background-color: #FFF
}

select {
  background-image:
    linear-gradient(45deg, transparent 50%, $secondary-color 50%),
    linear-gradient(135deg, $secondary-color 50%, transparent 50%);
  background-position:
    calc(100% - 30px) calc(1em + 12px),
    calc(100% - 20px) calc(1em + 12px);
  background-size:
    10px 10px,
    10px 10px;
  background-repeat: no-repeat;
}
