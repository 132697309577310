@import "variables";

.nav {
  background-color: $light-color;
  padding : 10px;
  margin: 0px;
  width: 100%;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 1
}

.nav--right, .nav--left {
  display: flex;
  align-items: center;
}
